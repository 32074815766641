import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { ConvertTimeformat, Date_Formate, Time_Formate } from '../../../assets/js/common.js';
import { Helper_Class } from '../../../app/helper_class';
import { OrdercancelComponent } from '../ordercancel/ordercancel.component';
import { ViewmedprespageComponent } from '../viewmedprespage/viewmedprespage.component';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { OrderConfirmPopupComponent } from '../order-confirm-popup/order-confirm-popup.component';
import { ipaddress } from '../../ipaddress';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Message_data } from 'src/assets/js/Message_data';
import moment from 'moment';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-pharmacymodule-orderdetiledview',
  templateUrl: './pharmacymodule-orderdetiledview.component.html',
  styleUrls: ['./pharmacymodule-orderdetiledview.component.css']
})
export class PharmacymoduleOrderdetiledviewComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  dureFlag: boolean;
  preferredTimeFlag: boolean;
  public discountDatasArray:any = [];
  public totAmount;
  dtOptions: DataTables.Settings = {};
  public discSalesVol:any = [];
  public discTransVol:any = [];
  public discRegSalesVol = [];
  public paid = "0.00";
  public remaingBalance;
  orderByName: String;
  prepareTime: String;
  orderNum: string;
  totTime: string;
  orderDate: string;
  orderTime: string;
  drugDetailsArray = [];
  taxDetail = [];
  taxPerc = [];
  amount: string;
  finalAmount: any;
  totalAmount: any;
  validityDays: string;
  availableTo: string;
  todayDate: string;
  purchaseType: string;
  addDelivery: boolean;
  presFlag: boolean;
  public orderType: boolean
  name: any;
  address1: any;
  address2: any;
  locationDesc: any;
  cityDesc: any;
  stateDesc: any;
  zipcode: any;
  contactNo: any;
  countryDesc: any;
  storeDiv: boolean;
  delDiv: boolean;
  deldate: any;
  deliverTime: any;
  public cancelBtn: boolean;
  confirmBtn: boolean;
  deliverBtn: boolean;
  printBtn: boolean;
  presobj: any;
  shortName: string;
  public confirmFlag: boolean;
  public hospName: string;
  public hospitalAddress: string;
  public hospCSZ: string;
  public hospTel: string;
  public hospitalCount: string;
  public batchNo: string;
  public medicalPrescription:any = [];
  public MedicineNameList:any = [];
  public MedicineList:any = [];
  public MedicneData:any = [];
  public batchArray:any = [];
  public medIDs:any = [];
  public drugArray:any = [];
  public medicareName: string;
  public medType: string;
  public drugType: string;
  public medicineName: string;
  public medID: string;
  public pharmaLogo: string;
  public billTemplate: string;
  public discType: string;
  public discAmount: string;
  public prodDisc;
  public amountdisc;
  public sendData;
  public price;
  public qty;
  public disc;
  public gst;
  public amountText;
  public perUnit;
  public amountBdGST;
  public productAmout;
  public getData
  public getDataValue;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  public discURl;
  public viewFlag: boolean;
  public discountValue: boolean;
  pdiscFlag: boolean;
  discFlag: boolean;
  public orderID;
  public totalValue1;
  public printFlag: boolean;
  public stateGST;
  public centralGST;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public pharmaAddress1;
  public pharmaAddress2;
  public dlNO;
  public GSTNumber;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public paytypeList:any = [];
  public insurerList:any = [];
  public payType: string;
  public insurerID: string;
  public prodFlag: boolean = false;
  public drName;
  public expiryDate;
  public userName;
  public batchRequired = { "border-color": "rgb(250, 40, 2)" };
  public recItemID;
  public MedicineNameArray:any = [];
  public medicineArray:any = [];
  public productReturn;
  public tempBatch;
  public batchFlag: boolean = false;
  public billEdit: boolean = false;
  public actuval: any;
  public payDes;
  public finyear: string;
  public backendquantity;
  public detailsofdrug:any = [];
  public inputquantity: number;
  public balancequantity;
  public backendindexvalue:any = [];
  public drugname;
  public drugcgst;
  public drugsgst;
  public drugexpiry_date;
  public drugdiscount;
  public drugamount;
  public drugprice;
  public totalamount;
  public nextBatchNo;
  public drugshortname;
  public drugfullname;
  public isAdmin;
  public payments:any=[];
  public paytypreq: boolean=false;

  constructor(public messageservice: PharmacyService, public comm_Data: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public adminService: MenuViewService) {
    this.cancelBtn = true;
    this.confirmBtn = true;
    this.deliverBtn = true;
    this.delDiv = true;
    this.printBtn = true;
    this.presFlag = false;
    this.discFlag = true;
    this.viewFlag = false;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.discURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.medicareName = "allopathy";
  }

  @HostListener('window:click', ['$event'])
  onclick(event: any): void {
    if (this.batchFlag == false) {
      if (this.drugDetailsArray.length >= 1) {
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          $('#drug_' + this.drugDetailsArray[i].index).css("display", "none");
        }
      }

    } else {
      this.batchFlag = false;
    }
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    
    if (pharmacy_helper.getorderview().value == "past") {
      this.orderType = false;
      this.discountValue = true;
    } else if (pharmacy_helper.getorderview().value == "past_edit") {
      this.orderType = true;
    } else {
      this.orderType = false;
    }
    this.getPaymentType();
    this.paid = "0.00";
    this.getDataValue = Helper_Class.getInfo();
    if(this.getDataValue.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    
    if(this.getDataValue.user_type =="Admin") {
      this.isAdmin=true;
    }
    this.pharmacistID = this.getDataValue.user_id;
    this.finyear = pharmacy_helper.getorderview().fin_year;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    if (Helper_Class.getIsAdmin() != undefined) {
      this.pharmacyID = this.getDataValue.pharma_id;
    } else {
      this.pharmacyID = this.getDataValue.hospitals[0].hptl_clinic_id;
    }

    this.pharmacyName = this.getDataValue.hospitals[0].hptl_name;
    this.pharmaAddress1 = this.getDataValue.hospitals[0].address1 != undefined && this.getDataValue.hospitals[0].address1 != null && this.getDataValue.hospitals[0].address1 != "" && this.getDataValue.hospitals[0].address1 != "undefined" ? this.getDataValue.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getDataValue.hospitals[0].address2 != undefined && this.getDataValue.hospitals[0].address2 != null && this.getDataValue.hospitals[0].address2 != "" && this.getDataValue.hospitals[0].address2 != "undefined" ? this.getDataValue.hospitals[0].address2 : undefined;
    this.location = this.getDataValue.hospitals[0].location_desc;
    this.city = this.getDataValue.hospitals[0].city_desc;
    this.state = this.getDataValue.hospitals[0].state_desc;
    this.country = this.getDataValue.hospitals[0].country_desc;
    this.dlNO = this.getDataValue.hospitals[0].dl_no != undefined && this.getDataValue.hospitals[0].dl_no != null && this.getDataValue.hospitals[0].dl_no != "" ? this.getDataValue.hospitals[0].dl_no : undefined;
    this.GSTNumber = this.getDataValue.hospitals[0].gst_no != undefined && this.getDataValue.hospitals[0].gst_no != null && this.getDataValue.hospitals[0].gst_no != "" ? this.getDataValue.hospitals[0].gst_no : undefined;
    if (this.getDataValue.middle_name != undefined) {
      this.userName = this.getDataValue.first_name + " " + this.getDataValue.middle_name + " " + this.getDataValue.last_name;
    } else {
      if (this.getDataValue.last_name != undefined) {
        this.userName = this.getDataValue.first_name + " " + this.getDataValue.last_name;
      } else {
        this.userName = this.getDataValue.first_name;
      }
    }
    this.ipaddress = Helper_Class.getIPAddress();
    this.orderID = pharmacy_helper.getorderview().orderid;
    this.checkPrescriptionType();
    this.getData = pharmacy_helper.getorderview();
    if (this.getData.status != null) {
      if (this.getData.status == "0") {
        this.cancelBtn = false;
        this.confirmBtn = false;
        this.deliverBtn = true;

      } else if (this.getData.status == "1") {
        this.cancelBtn = true;
        this.confirmBtn = true;
        this.deliverBtn = false;
        this.printBtn = false;

      } else if (this.getData.status == "2") {
        this.confirmBtn = false;
        this.printBtn = true;

      } else {
        this.cancelBtn = true;
        this.confirmBtn = true;
        this.deliverBtn = false;
        this.printBtn = true;
      }
    }
    this.billEdit = Helper_Class.getBillEdit() == "1" ? true : false;
    this.addDelivery = true;

    this.currentDate();
    this.getDiscTypes();
    this.getDiscountDetails();
    var send_data = {
      order_id: this.orderID,
      pharmacy_id: this.pharmacyID,
      fin_year: this.finyear,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", JSON.stringify(send_data),
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          console.log("object value of object", JSON.stringify(obj))
          this.detailsofdrug.push(obj.drug_details)
          console.log(JSON.stringify(this.detailsofdrug))

          if (obj != null) {
            this.viewFlag = false;
            if (obj.dr_first_name != undefined) {
              this.drName = obj.dr_middle_name != undefined ? obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name : obj.dr_first_name + " " + obj.dr_last_name;
            } else {
              this.drName = obj.dr_name;
            }
            this.confirmFlag = obj.is_confirm == "1" ? true : false;
            this.prepareTime = Time_Formate(obj.order_time);
            if (obj.middle_name != undefined) {
              this.orderByName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
            
            } else {
              this.orderByName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }
            this.presFlag = obj.pres_drug_id != undefined ? false : true;
            this.orderNum = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
            this.purchaseType = obj.description != undefined ? obj.description : "";
            if (obj.preferred_from_time != undefined) {
              this.totTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
              this.preferredTimeFlag = false;
            } else {
              this.preferredTimeFlag = true;
            }

            if (obj.order_date != undefined) {
              var dateval = obj.order_date.split("-");
              this.orderDate = obj.order_date != undefined ? dateval[2]+"-"+dateval[1]+"-"+dateval[0] : undefined;
            }
            this.orderTime = obj.order_time != undefined ? Time_Formate(obj.order_time) : "";
            this.remaingBalance = obj.balance;
            this.paid = obj.paid_amount != undefined ? obj.paid_amount : "0.00";
            if (this.paid == "") {
              this.paid = "0.00";
            }
            this.actuval = parseFloat(obj.bill_amount).toFixed(2);
            this.totalValue1 = parseFloat(obj.bill_amount).toFixed(2);
            this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
            // }
            this.payType = obj.pay_type;
            this.payDes = obj.pay_desc;
            if(obj.payments != undefined) {
              for(var i=0;i<obj.payments.length;i++) {
                var transid;
                if(obj.payments[i].pay_type =="2" 
                  || obj.payments[i].pay_type =="4" 
                  || obj.payments[i].pay_type =="5"
                  || obj.payments[i].pay_type =="5") {
                  transid =obj.payments[i].trans_id; 
                } else if(obj.payments[i].pay_type =="3") {
                  transid =obj.payments[i].insurer;
                }
                
                var cardno;
                if(obj.payments[i].pay_type =="2"){
                  cardno = obj.payments[i].card_no;
                } else {
                  cardno="";
                }

                this.payments.push({
                  pay_desc:obj.payments[i].pay_desc,
                  amount:obj.payments[i].amount,
                  trans_id:transid,
                  card_no:cardno,
                })
              }
              this.payments = obj.payments;
            }

            if (obj.drug_details != null) {
              var index = 0;
              for (var i = 0; i < obj.drug_details.length; i++) {
                var discount;
                if (obj.drug_details[i].discount != "0") {
                  discount = obj.drug_details[i].discount;
                } else {
                  discount = obj.drug_details[i].prod_disc != undefined ? obj.drug_details[i].prod_disc : "0";
                }
                var amtaftergst;
                if (pharmacy_helper.getorderview().AppFlow == "current_orders") {
                  if (discount != "") {
                    var value = parseInt(obj.drug_details[i].amount) - (parseInt(obj.drug_details[i].amount) * (parseInt(discount) / 100));
                    amtaftergst = (value + (value)).toFixed(2);
                  } else {
                    amtaftergst = parseInt(obj.drug_details[i].amount) + (parseInt(obj.drug_details[i].amount));
                  }
                } else {
                  amtaftergst = obj.drug_details[i].amount;
                }
                var price = parseFloat("0.00");
                var amount;
                if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
                  price = obj.drug_details[i].price;
                  amount = amtaftergst;
                } else {
                  price = 0.00;
                  amount = 0.00;
                }

                var tempDrugDetails = {
                  drug_id: obj.drug_details[i].drug_id,
                  type_name: obj.drug_details[i].type_name,
                  drug_name: obj.drug_details[i].drug_name,
                  drug_disp_name: obj.drug_details[i].short_name + " " + obj.drug_details[i].drug_name,
                  quantity: obj.drug_details[i].quantity,
                  amount: parseFloat(amount).toFixed(2),
                  price: price,
                  discount: discount,
                  per_unit: obj.drug_details[i].per_unit,
                  prod_disc: obj.drug_details[i].prod_disc,
                  batch_no: obj.drug_details[i].batch_no,
                  batchnos: obj.drug_details[i].batchnos,
                  cgst: obj.drug_details[i].cgst,
                  sgst: obj.drug_details[i].sgst,
                  batch_hide: this.orderType,
                  expiry_date: obj.drug_details[i].expiry_date != undefined ? obj.drug_details[i].expiry_date : '',
                  rec_item_id: obj.drug_details[i].rec_item_id,
                  index: index,
                  fromRetrivel: true
                }

                console.log("temp drug details" + JSON.stringify(tempDrugDetails))
                this.drugDetailsArray.push(tempDrugDetails);
                index++;
                this.getItemPrices(tempDrugDetails);
              }

              var prec_tx = 0;
              for (var i = 0; i < this.drugDetailsArray.length; i++) {
                prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
              }
              this.finalAmount = 0;
              var x = prec_tx + parseFloat(this.finalAmount);
              x = Math.round(x * 100) / 100;
              this.totalAmount = x.toFixed(2);

              if (this.totalAmount == "NaN") {
                this.totalAmount = "0.00";
              }
              this.discType = obj.disc_type != undefined ? obj.disc_type : this.discType;
              if (obj.disc_amt != undefined && obj.disc_amt != null && obj.disc_amt != "" && pharmacy_helper.getorderview().value != "past_edit") {
                this.prodDisc = obj.disc_amt;
                this.discFlag = false;
                this.amountdisc = this.totalAmount - this.prodDisc;
                this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

                this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
                this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);

              } else if (pharmacy_helper.getorderview().value == "past_edit") {
                this.prodDisc = obj.disc_amt;
                this.discFlag = false;
                this.amountdisc = this.prodDisc;
                this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

                this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
                this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);

              } else {
                this.amountdisc = this.totalAmount;
                this.totalValue1 = parseFloat(this.totalAmount).toFixed(2);

                this.actuval = parseFloat(this.totalAmount).toFixed(2);
                this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
                this.discFlag = true;
              }
              this.remaingBalance = (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);
            }

            if (obj.description == "Store pickup") {
              this.storeDiv = false;
              this.delDiv = true;
              this.addDelivery = true;
              var someDate = new Date(obj.order_date);
              if (obj.validity_days != undefined) {
                someDate.setDate(someDate.getDate() + parseFloat(obj.validity_days));
              }
              var dateFormated = someDate.toISOString().substr(0, 10);
              var validdate = dateFormated.split('-');
              this.validityDays = validdate[2] + "-" + validdate[1] + "-" + validdate[0]
              this.availableTo = Time_Formate(obj.available_to);
            } else {
              this.addDelivery = false;
              this.storeDiv = true;
              if (obj.delivered_date != null) {
                var deldate = obj.delivered_date.split('-');
                var deldate_txt = deldate[2] + "-" + deldate[1] + "-" + deldate[0]
                var timeString = obj.delivered_time;
                var H = +timeString.substr(0, 2);
                var h = (H % 12) || 12;
                var ampm = H < 12 ? "AM" : "PM";
                timeString = h + timeString.substr(2, 3) + " " + ampm;
                this.deliverTime = timeString;
                this.deldate = deldate_txt;
                this.delDiv = false;
              }
              this.name = obj.name;
              this.address1 = obj.address2 != undefined ? this.address1 + "," + obj.address2 : this.address1;
              this.locationDesc = obj.location;
              this.cityDesc = obj.city_desc;
              this.stateDesc = obj.state_desc;
              this.zipcode = obj.zipcode;
              this.countryDesc = obj.country_desc != null && obj.country_desc != undefined ? "-" + obj.country_desc : "";
              this.contactNo = obj.contact_no;
            }
            this.hospName = obj.pharmacy_name;
            this.hospitalAddress = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;
            this.hospCSZ = obj.location + ", " + obj.phar_city + " - " + obj.phar_zipcode;
            this.hospitalCount = obj.phar_state + ", " + obj.phar_country;
            this.hospTel = obj.phar_telephone != undefined ? obj.phar_telephone : "-";
            this.pharmaLogo = ipaddress.Ip_with_img_address + obj.bill_logo;
            this.billTemplate = obj.bill_template;
            console.log("this.drugDetailsArray" + JSON.stringify(this.drugDetailsArray));
          }
        });
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.paytypeList = obj.payments;
          this.payType = this.paytypeList[0].pay_id;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  clearmedtype() {
    this.medType = null;
    this.drugType = undefined;
    this.medicineName = null;
    this.MedicineNameList = [];
  }

  clearmedname() {
    this.medicineName = null;
    this.medID = undefined;
    this.MedicineNameList = [];
  }

  getMedicineType(e) {
    var headers = new Headers();
    if (this.medType != null && this.medType != undefined && this.medType.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        JSON.stringify({
          medicare_id: this.medicareName,
          medicine_name: this.medType
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.medicalPrescription = [];
            this.medicalPrescription = obj.med_details;
          },
          error => { });
    }
  }

  selectMedicineType(item) {
    this.medType = item.med_name;
    this.drugType = item.med_code;
    this.medicalPrescription = [];
  }

  changeMedicineName(e) {
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: "medicine"
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                this.MedicneData.push(medname);
                this.medicineArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                })
              }
              this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectMedicineItem(item, drug_detailsarray) {
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item == this.medicineArray[i].medname) {
        drug_detailsarray.drug_name = item;
        drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        this.medicineName = this.medicineArray[i].med_name;
        drug_detailsarray.cgst = this.medicineArray[i].cgst;
        drug_detailsarray.sgst = this.medicineArray[i].sgst;
      }
    }
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }

  getItemPrices(drug_detailsarray) {
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        console.log("drugdata-price" + JSON.stringify(dataval))
        if (dataval != undefined && dataval.length != 0) {
          for (var j = 0; j < this.drugDetailsArray.length; j++) {
            if (this.drugDetailsArray[j].drug_id == drug_detailsarray.drug_id) {
              this.drugDetailsArray[j].batcharray = [];
              var index = 0;
              for (var i = 0; i < dataval.pharma_price.length; i++) {
                var color = this.checkExpiryDate(dataval.pharma_price[i].expiry_date);
                this.drugArray.push({
                  price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                  per_unit: dataval.per_unit,
                  batch_no: dataval.pharma_price[i].batch_no,
                  quantity: dataval.pharma_price[i].avail_qty,
                  discount: dataval.pharma_price[i].prod_disc,
                  cgst: dataval.pharma_price[i].prod_cgst,
                  sgst: dataval.pharma_price[i].prod_sgst,
                  expiry_date: dataval.pharma_price[i].expiry_date,
                  rec_item_id: dataval.pharma_price[i].rec_item_id,
                  index: index,
                });

                this.drugDetailsArray[j].batcharray.push({
                  index: index,
                  batch_no: dataval.pharma_price[i].batch_no,
                  rec_item_id: dataval.pharma_price[i].rec_item_id,
                  mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                  quantity: dataval.pharma_price[i].avail_qty,
                  expiry_date: dataval.pharma_price[i].expiry_date,
                  color: color,
                });
                index++;
              }
              break;
            }
          }
          console.log("VERIFY DRUG PURCHASE "+JSON.stringify(this.drugDetailsArray))
        }

      }, error => { }
    );
  }

  retrieveBatch(medpre) {
    this.medIDs = [];
    this.medIDs.push(medpre.drug_id);
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: this.medIDs,
      batch_no: medpre.batch_no,
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/itemprice/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        if (obj.pharma_price != undefined) {
          for (var i = 0; i < obj.pharma_price.length; i++) {
            for (var j = 0; j < this.drugDetailsArray.length; j++) {
              if (this.drugDetailsArray[j].drug_id == medpre.drug_id) {
                var rate_per_unit = (parseFloat(obj.pharma_price[i].price) / parseInt(obj.pharma_price[i].per_unit)).toFixed(2);
                this.drugDetailsArray[j].amt_bdgst = this.drugDetailsArray[j].quantity * parseFloat(rate_per_unit);
                this.drugDetailsArray[j].price = rate_per_unit;
                this.drugDetailsArray[j].discount = obj.pharma_price[i].discount != undefined ? obj.pharma_price[i].discount : "0";
                this.drugDetailsArray[j].cgst = obj.pharma_price[i].prod_cgst != undefined && obj.pharma_price[i].prod_cgst != "" ? obj.pharma_price[i].prod_cgst : "0";
                this.drugDetailsArray[j].sgst = obj.pharma_price[i].prod_sgst != undefined && obj.pharma_price[i].prod_sgst != "" ? obj.pharma_price[i].prod_sgst : "0";
                this.drugDetailsArray[j].rec_item_id = obj.pharma_price[i].rec_item_id;
                this.drugDetailsArray[j].expiry_date = obj.pharma_price[i].expiry_date;
                this.calculatePrice(medpre.drug_id, medpre.type_name, medpre.drug_name, medpre.quantity, rate_per_unit, medpre.per_unit, medpre.discount, medpre.index)
              }
            }
          }
        }
      })
  }

  clearQuantity() {
    this.qty = undefined;
  }

  isReadonly() { return true; }
  currentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
        },
      )
  }

  clearField(data) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == data.drug_id) {
        this.drugDetailsArray[i].quantity = "";
        break;
      }
    }
  }
  public checkindex;
  public checkbatchNo;
  public batcharraybatchno;
  public subractnumber;

  calculatePrice(drugid, typename, drugname, quantity, price, per_unit, discount, index) {
    if (quantity != "") {
      var priceval = parseFloat(price);
      var pricecal: any = priceval * quantity;
      if (discount != null) {
        var disc: any = (pricecal * (discount / 100)).toFixed(2);
      }
      var pricegst = parseFloat(pricecal) - parseFloat(disc);
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.checkindex == i) {
          this.inputquantity = parseInt(this.drugDetailsArray[i].quantity)
          this.batcharraybatchno = parseInt(this.drugDetailsArray[i].batch_no)
        }

        if (drugid == this.drugDetailsArray[i].drug_id) {
          if (index == this.drugDetailsArray[i].index) {
            this.drugDetailsArray[i].quantity = quantity;
            this.drugDetailsArray[i].amount = pricegst.toFixed(2);
            this.drugDetailsArray[i].price = price;
            this.drugDetailsArray[i].discount = discount;
            this.drugDetailsArray[i].amt_bdgst = pricecal;
          }
        }
      }

      var totamount = 0;
      for (var j = 0; j < this.drugDetailsArray.length; j++) {
        totamount += parseFloat(this.drugDetailsArray[j].amount);
      }

      this.totalValue1 = totamount.toFixed(2);

      this.actuval = totamount.toFixed(2);
      this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);

      this.remaingBalance = (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);
      this.taxDetail = [];
      if (this.taxPerc != null) {
        for (var i = 0; i < this.taxPerc.length; i++) {
          var taxtxt = ((totamount * this.taxPerc[i].tax) / 100).toFixed(2);
          this.taxDetail.push({
            tax_des: this.taxPerc[i].tax_des,
            tax_id: taxtxt,
          })
        }
      }
      this.finalAmount = 0;
      for (var i = 0; i < this.taxDetail.length; i++) {
        this.amount = this.taxDetail[i].tax_id;
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.amount)).toFixed(2);
      }
      var x = totamount + parseFloat(this.finalAmount);
      x = Math.round(x * 100) / 100;
      this.totalAmount = x.toFixed(2);
    }
    this.calculateDiscount();
  }
  public nextbatchquantity;
  public singledrugbactch;
  public checknextbatchquantity;

  comparedrug(drug_id, medpre) {
    var totamount;
    this.checkindex = drug_id
    console.log("singlel medpre" + JSON.stringify(medpre))
    var singeldrugbatchno = medpre.batch_no
    console.log("check index value", drug_id)
    console.log("the drug id value", this.checkindex)

    console.log("details of drug---------->>" + this.detailsofdrug)
    const selectedDrug = this.drugDetailsArray.find(drug => drug.drug_id === this.checkindex);

    if (selectedDrug) {
      selectedDrug.batcharray.forEach(batch => {
        this.checkbatchNo = batch.batch_no;
        var batchNo = batch.batch_no
        var batchquantity = batch.quantity


        if (batchNo == singeldrugbatchno) {
          this.backendquantity = batch.quantity;
        }

        var subract = this.inputquantity - this.subractnumber
        if (batchNo != singeldrugbatchno) {
          this.nextBatchNo = batchNo
          this.checknextbatchquantity = batchquantity
        }
      });
    }

    for (var n = 0; n < this.detailsofdrug.length; n++) {
      for (var k = 0; k < this.detailsofdrug[n].length; k++) {
        if (this.checkindex == this.detailsofdrug[n][k].drug_id) {
          this.backendindexvalue = this.detailsofdrug[n][k].drug_id,
            this.drugname = this.detailsofdrug[n][k].drug_name,
            this.drugshortname = this.detailsofdrug[n][k].short_name
            this.drugprice = this.detailsofdrug[n][k].price
            this.drugsgst = this.detailsofdrug[n][k].sgst,
            this.drugcgst = this.detailsofdrug[n][k].cgst,
            this.drugexpiry_date = this.detailsofdrug[n][k].expiry_date,
            this.drugdiscount = this.detailsofdrug[n][k].discount,
            this.drugfullname = this.drugshortname + " " + this.drugname
        }
      }

      if (this.checkindex == this.backendindexvalue) {
        if (this.inputquantity > this.backendquantity) {
          this.balancequantity = this.inputquantity - this.backendquantity
          this.totalamount = this.drugprice * this.balancequantity
          medpre.quantity = this.backendquantity
          this.checkquantity()
        }
      }
    }
  }

  checkquantity() {
    var index = 1;

    this.drugDetailsArray.push({
      drug_id: this.checkindex,
      type_name: "",
      drug_name: this.drugfullname,
      drug_disp_name: "",
      quantity: this.balancequantity,
      amount: this.totalamount.toFixed(2),
      price: this.drugprice,
      discount: this.drugdiscount,
      per_unit: "",
      prod_disc: "",
      batch_no: this.nextBatchNo,
      amt_bdgst: "",
      cgst: this.drugcgst,
      sgst: this.drugsgst,
      expiry_date: this.drugexpiry_date,
      rec_item_id: "",
      index: index,
      balance: this.remaingBalance,
      batch_required: { "border-color": "rgb(250, 40, 2)" },
      batcharray: "",
      show_batcharray: false,
      quantityDisable: true,
      fromRetrivel: false

    });
    this.totalValue1 = parseInt(this.totalValue1) + parseInt(this.totalamount.toFixed(2));
    this.remaingBalance = (parseFloat(this.totalValue1) - parseFloat(this.paid)).toFixed(2);

    this.drugDetailsArray.sort((a, b) => {
      return b.drug_id.localeCompare(a.drug_id);
    });
  }

  cancelProduct() {
    const dialogRef = this.dialog.open(OrdercancelComponent, {
      width: '40%',
      height: '180px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/rejeord',
          JSON.stringify({
            order_id: this.orderID,
            reject_text: data
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.result != null) {
                if (obj.result == "Order has been rejected") {
                  this.toastr.success(Message_data.cancelOrderSuccess);
                  this.messageservice.sendMessage('pharmacycurentinformation');
                } else {
                  this.toastr.error(Message_data.orderNotRejected);
                }
              }
            },
            error => {
              this.toastr.error(Message_data.network);
            });
      }
    });
  }
  checkPrescriptionType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/durlbyord',
      JSON.stringify({
        order_id: this.orderID
      }),
      { headers: headers })
      .subscribe(
        response => {
          this.presobj = JSON.parse(response["_body"]);
          if (this.presobj.key == "1" && localStorage.getItem("currentorderflowstatus")) {
            this.toastr.success(Message_data.viewPresBfrSave);
          }
        });
  }
  viewPrescription() {
    const dialogRef = this.dialog.open(ViewmedprespageComponent, {
      width: '65%',
      height: '510px',
      data: { order: this.orderID, pharmacy_id: this.pharmacyID }
    });
    dialogRef.afterClosed().subscribe(data => { });
  }
  confirmProduct() {
    var delup = this.todayDate.split('-');
    var delviery = this.todayDate;
    var delivery_time = "06:00:00";
    var SendJSON = null;
    if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
      if (this.purchaseType != "Store pickup") {
        const dialogRef = this.dialog.open(OrderConfirmPopupComponent, {
          width: '320px',
          height: '400px',
          data: {
            today: this.todayDate,
            type: this.purchaseType
          }
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data != undefined && data.selecteddate != undefined) {
            delup = data.selecteddate.split('-');
            delviery = delup[2] + "-" + delup[1] + "-" + delup[0];
            delivery_time = ConvertTimeformat("", data.selectedtime);
            SendJSON = JSON.stringify({
              delivery_date: data.selecteddate,
              delivery_time: delivery_time,
              order_id: this.orderID,
              drug_details: this.drugDetailsArray,
              disc_type: this.discType,
              disc_amt: this.prodDisc,
              pharma_id: this.pharmacyID,
              bill_amt: this.totalValue1,
              paid_amt: this.paid,
              actuval: this.actuval,
              balance: this.remaingBalance,
              pay_type: this.payType,
              insurer: this.insurerID,
              card_holder_name: this.cardHolderName,
              transaction_no: this.transactionID,
              bank_name: this.bankName,
              purchase_type_id: "2",
              fin_year: this.finyear,
            });
            console.log("SendJSON" + SendJSON);
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp + "pharmacontrol/upord", SendJSON,
              { headers: headers }).subscribe(
                response => {
                  var obj = JSON.parse(response["_body"]);
                  if (obj.key == "1") {
                    this.confirmBtn = true;
                    this.toastr.success(Message_data.orderConfirmSuccess);
                    if (Helper_Class.getIsAdmin() != undefined) {
                      this.adminService.sendMessage("pharmaBillingList")
                    } else {
                      this.messageservice.sendMessage("pharmacycurentinformation");
                    }

                  } else {
                    this.confirmBtn = false;
                    this.toastr.error(Message_data.orderConfirmFailure);
                  }
                },
              )
          }
        });
      } else {
        this.prodFlag = false;
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          if (this.drugDetailsArray[i].batch_no == undefined) {
            this.prodFlag = true;
            this.toastr.error(Message_data.fillBatchNoPrd);
            break;
          }
        }
        if (this.prodFlag == false) {
          var paid_flag;
          if (this.totalValue1 == this.remaingBalance) {
            paid_flag = "Un paid";
          } else if (this.totalValue1 != "0.00" && (this.paid != "0.00") && this.totalValue1 != this.paid) {
            paid_flag = "Partially paid";
          } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
            paid_flag = "Fully paid";
          }
          var senddata = {
            delivery_date: delviery,
            delivery_time: delivery_time,
            order_id: this.orderID,
            medicines: this.drugDetailsArray,
            pharma_id: this.pharmacyID,
            disc_type: this.discType,
            disc_amt: this.prodDisc,
            bill_amt: this.totalValue1,
            paid_amt: this.paid,
            actuval: this.actuval,
            balance: this.remaingBalance,
            paid_flag: paid_flag,
            pay_type: this.payType,
            insurer: this.insurerID,
            card_holder_name: this.cardHolderName,
            transaction_no: this.transactionID,
            bank_name: this.bankName,
            purchase_type_id: "1",
            created_by: this.userName,
            fin_year: this.finyear,
          }
          console.log("SendJSON" + JSON.stringify(senddata));

          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp + "pharmacontrol/upord", JSON.stringify(senddata),
            { headers: headers }).subscribe(
              response => {
                var obj = response.json();
                if (obj.key == "1") {
                  this.confirmBtn = true;
                  this.toastr.success(Message_data.orderConfirmSuccess);
                  if (Helper_Class.getIsAdmin() != undefined) {
                    this.adminService.sendMessage("pharmaBillingList")
                  } else {
                    this.messageservice.sendMessage("pharmacycurentinformation");
                  }
                } else {
                  this.confirmBtn = false;
                  this.toastr.error(Message_data.orderConfirmFailure);
                }
              }, error => { this.confirmBtn = false; })
        }
      }
    } else {
      this.prodFlag = false;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].batch_no == undefined) {
          this.prodFlag = true;
          this.toastr.error(Message_data.fillBatchNoPrd);
          break;
        }
      }
      if (this.prodFlag == false) {
        var paid_flag;
        if (this.totalValue1 == this.remaingBalance) {
          paid_flag = "Un paid";
        } else if (this.totalValue1 != "0.00" && (this.paid != "0.00") && this.totalValue1 != this.paid) {
          paid_flag = "Partially paid";
        } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
          paid_flag = "Fully paid";
        }
        var send_data = {
          delivery_date: delviery,
          delivery_time: delivery_time,
          order_id: this.orderID,
          medicines: this.drugDetailsArray,
          pharma_id: this.pharmacyID,
          disc_type: this.discType,
          disc_amt: this.prodDisc,
          bill_amt: this.totalValue1,
          actuval: this.actuval,
          paid_amt: this.paid,
          balance: this.remaingBalance,
          paid_flag: paid_flag,
          pay_type: this.payType,
          insurer: this.insurerID,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionID,
          bank_name: this.bankName,
          fin_year: this.finyear,
        }
        console.log("SendJSON" + JSON.stringify(send_data));
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "pharmacontrol/orderconfirm", JSON.stringify(send_data),
          { headers: headers }).subscribe(
            response => {
              var obj = response.json();
              if (obj.key == "1") {
                this.confirmBtn = true;
                this.toastr.success(Message_data.orderConfirmSuccess);
                if (Helper_Class.getIsAdmin() != undefined) {
                  this.adminService.sendMessage("pharmaBillingList")
                } else {
                  this.messageservice.sendMessage("pharmacycurentinformation");
                }
              } else {
                this.confirmBtn = false;
                this.toastr.error(Message_data.orderConfirmFailure);
              }
            }, error => { this.confirmBtn = false; })
      }
    }
  }
  deliverProduct() {
    var delup = this.todayDate.split('-');
    var delviery = delup[2] + "-" + delup[1] + "-" + delup[0]
    var delivery_time = "06:00:00";
    var SendJSON = null;
  }
  addMedicines() {
    var index = 0;
    if (this.drugDetailsArray.length != 0) {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        index = this.drugDetailsArray[i].index + 1;
      }
    }
    this.drugDetailsArray.push({
      drug_id: this.medID,
      type_name: this.medType,
      drug_name: this.medicineName,
      drug_disp_name: this.shortName + " " + this.medicineName,
      quantity: this.qty,
      amount: this.amountText,
      price: this.price,
      discount: this.disc,
      per_unit: this.perUnit,
      prod_disc: this.disc,
      batch_no: this.batchNo,
      batch_hide: false,
      amt_bdgst: parseFloat(this.productAmout).toFixed(2),
      cgst: this.centralGST,
      sgst: this.stateGST,
      expiry_date: this.expiryDate != undefined ? this.expiryDate : '',
      rec_item_id: this.recItemID,
      index: index,
    });
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
    }


    this.totalValue1 = prec_tx.toFixed(2);

    this.actuval = prec_tx.toFixed(2);
    this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
    this.medType = "";
    this.medicineName = "";
    this.qty = "";
    this.disc = "";
    this.gst = "";
    this.amountText = "";
    this.price = "";
    this.centralGST = "";
    this.stateGST = "";
    this.expiryDate = "";
    this.recItemID = "";
    this.batchNo = "";
    this.batchRequired = { "border-color": "rgb(250, 40, 2)", }
    this.changeAdvance();
  }
  deleteMedicine(drug_id, index) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == drug_id) {
        if (this.drugDetailsArray[i].index == index) {
          this.drugDetailsArray.splice(i, 1);
        }
      }
    }
    var prec_tx = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);
    }
    if (this.drugDetailsArray.length == 0) {
      this.totalAmount = "0.00";
      this.totalValue1 = "0.00";

      this.actuval = "0.00";
      this.remaingBalance = "0.00";
    } else {
      this.totalAmount = prec_tx;
      this.totalValue1 = prec_tx;

      this.actuval = prec_tx;
      this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);

      this.remaingBalance = prec_tx;
    }
    this.changeAdvance();
  }
  printArea() {
    var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
    var template;
    if (this.billTemplate != undefined && this.billTemplate == "banner") {
      template = '<div #banner id="banner">'
    } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
      template = '<div #noheader id="noheader">'
    } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
      template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
    } else {
      template = '<div #billprint id="billprint">';
    }

    var printData = {
      template: this.billTemplate,
      content: template,
      pharma_logo: this.pharmaLogo,
      pharma_name: this.pharmacyName,
      pharma_addr: pharmaAddr,
      location: this.location,
      city: this.city,
      state: this.state,
      country: this.country,
      dl_no: this.dlNO,
      gst_no: this.GSTNumber,
      patient_name: this.orderByName,
      bill_no: this.orderNum,
      PurchaseType: this.purchaseType,
      doc_name: this.drName,
      order_date: this.orderDate,
      order_time: this.orderTime,
      drugList: this.drugDetailsArray,
      discount: this.prodDisc != null ? this.prodDisc : '0',
      total: this.totalValue1,
      paid: this.paid,
      balance: this.remaingBalance,
      order_type: this.orderType,
      show_discount: false,
      pay_type: this.payDes,
      payments:this.payments,
    }
    Helper_Class.setbillprintgen(this.orderNum)
    this.printService.print(printData);
    if (this.confirmBtn == false) {
      this.confirmProduct();
    }
  }
  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.discURl, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        this.discountDatasArray = distypes.disc_types;
        this.discType = this.discountDatasArray[0].disc_type_id;
      }, error => { });
  }

  discountChange() {
    if (this.discType != "1" && this.discType != "2") {
      this.discFlag = false;
      if (this.discRegSalesVol != undefined && this.discRegSalesVol.length == 0) {
        this.getDiscountDetails();
      }
    } else {
      this.discFlag = true;
    }
    this.calculateDiscount();
  }

  calculateDiscount() {
    var tamtbg = 0.00;
    var amtval = 0.00;
    this.prodDisc = null;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].amt_bdgst != 'undefined' && this.drugDetailsArray[i].amt_bdgst != undefined) {
        tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);
      }
      if (this.drugDetailsArray[i].amount != 'undefined' && this.drugDetailsArray[i].amount != undefined) {
        amtval = amtval + parseFloat(this.drugDetailsArray[i].amount);
      }
    }
    if (this.discType == "3") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discRegSalesVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discRegSalesVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discRegSalesVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);
          this.totalValue1 = (Math.round(this.totalAmount)).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          //this.totalValue1 =  (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else if (this.discType == "4") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discSalesVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discSalesVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);

          this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else if (this.discType == "5") {
      this.amountBdGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discTransVol.length; j++) {
        if (parseInt(this.amountBdGST) >= this.discTransVol[j].volume) {
          var disamt = parseInt(this.amountBdGST) * (this.discTransVol[j].discount / 100);
          this.prodDisc = Math.floor(disamt).toFixed(2);
          this.totalValue1 = (this.totalAmount - this.prodDisc).toFixed(2);

          this.actuval = (this.totalAmount - this.prodDisc).toFixed(2);
          this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
        }
      }
    } else {
      this.totalValue1 = amtval.toFixed(2);

      this.actuval = amtval.toFixed(2);
      this.totalValue1 = (Math.round(parseFloat(this.totalValue1))).toFixed(2);
    }
    this.changeTotal();
  }

  changeTotal() {
    this.amountdisc = (parseFloat(this.totalAmount) - parseFloat(this.prodDisc)).toFixed(2);
    this.prodDisc = this.prodDisc == "" || this.prodDisc == null ? "0.00" : this.prodDisc;
    this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);
  }

  getDiscountDetails() {
    this.discRegSalesVol = [];
    this.discSalesVol = [];
    this.discTransVol = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.discRegSalesVol = obj.regsal_vol;
          this.discSalesVol = obj.sales_vol;
          this.discTransVol = obj.trans_vol;
        });
  }

  changeAdvance = function (this: any) {
    this.prodDisc = this.prodDisc != undefined && this.prodDisc != "" ? this.prodDisc : "0.00";
    if (this.paid != undefined && this.paid != "") {
      // if (this.amount_disc != "") {
      this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);
      // } else {
      //   this.remain_balance = (parseFloat(this.totalvalue1) - parseFloat(this.paid)).toFixed(2);
      // }
      this.paid = parseFloat(this.paid);
    } else {
      this.paid = "0.00";
      this.remaingBalance = (parseFloat(this.totalValue1) - ((parseFloat(this.paid)) + (parseFloat(this.prodDisc)))).toFixed(2);
    }
  }

  paidText() {
    this.paid = parseFloat(this.paid).toFixed(2);
  }

  back_Nav() {
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingList")
    } else {
      if (pharmacy_helper.getorderview().value == "past" || pharmacy_helper.getorderview().value == "past_edit") {
        this.messageservice.sendMessage("pharmacypastinformation");
      } else {
        this.messageservice.sendMessage("pharmacycurentinformation");
      }
    }
  }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    return color;
  }

  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    $("#" + i).css("position", "fixed");
    $("#" + i).css("display", "block");
    $("#" + i).css("top", y + 'px');
    $("#pharma_tbl_overlay").css("overflowY", "overflowY");
  }

  selectedBatch(batch, drug_detailsarray, index) {
    this.batchFlag = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
    drug_detailsarray.batch_no = batch.batch_no;
    if (drug_detailsarray.batch_no != "")
      drug_detailsarray.quantityDisable = false;
    for (var i = 0; i < this.drugArray.length; i++) {
      if (batch.batch_no == this.drugArray[i].batch_no) {
        if (batch.index == this.drugArray[i].index) {
          if (this.tempBatch != undefined) {
            drug_detailsarray.price = this.drugArray[i].price_txt;
            // drug_detailsarray.quantity = this.drugArray[i].quantity;
          } else {
            drug_detailsarray.price = this.drugArray[i].price_txt;
            //drug_detailsarray.quantity = this.drugArray[i].quantity;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id
          }
          //this.calculateamount(drug_detailsarray);
          this.calculatePrice(drug_detailsarray.drug_id, drug_detailsarray.type_name, drug_detailsarray.drug_name, drug_detailsarray.quantity, drug_detailsarray.price, drug_detailsarray.per_unit, drug_detailsarray.discount, drug_detailsarray.index)
        }
      }
    }
    drug_detailsarray.show_batcharray = false;
    document.getElementById(index).style.display = 'none';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
    document.getElementById("tbl_med").style.overflowY = "auto";
  }
  calculateamount(drug_detailsarray) {
    if (drug_detailsarray.quantity != "") {
      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }
      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }
      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
    }
  }
  addNewDrugDetails() {
    var index = 0;
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        index: index,
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: [],
        show_batcharray: false,
        quantityDisable: true,
        fromRetrivel: false,
        balance: this.remaingBalance
      });
    } else {
      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            index: index,
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            show_batcharray: false,
            quantityDisable: true,
            fromRetrivel: false,
            balance: this.remaingBalance
          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);
  }
}
